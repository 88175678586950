import Feature from '../../components/Feature'
import Quote from '../../components/Quote'
import CallToAction from './CallToAction'

import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye.svg'
import { ReactComponent as BinocularsIcon } from '../../../../assets/icons/binoculars.svg'
import { ReactComponent as PriceAlertIcon } from '../../../../assets/icons/price-alert.svg'

export default function Reporting() {
  return (
    <div id="reporting" className="flex flex-col container max-w-5xl mx-auto my-8 md:my-32 px-4">
      <div className="flex flex-col container max-w-xl mx-auto mb-8 md:mb-16">
        <h2 className="mb-4 text-2xl md:text-3xl font-bold">Take control of your supply spend</h2>
        <p className="leading-normal text-neutral-700 mb-6">As a business owner, you deserve the ability to easily track your supply spend. But with spending spread across all types of vendors, it can be nearly impossible to wrap your head around in real-time. Which means by the time there’s a problem, it’s too late.</p>
        <p className='leading-normal text-neutral-700'>Details centralizes your supply spend data into one dashboard, so you can see everything  in one place. Keeping spend data front-of-mind gives you valuable insight, and helps staff keep costs top-of-mind while ordering.</p>
      </div>

      <div className="flex flex-col md:flex-row gap-8 mb-16">
        <Feature
          heading="Get full visibility"
          content="You'll see all of your practice's supply spend in one place, always up-to-date, as it's happening on Details' live reporting dashboard."
          icon={<EyeIcon />}
          className="basis-1/3"
        />

        <Feature
          heading="Drill into the details"
          content="Break down your spend to answer questions like 'What am I typically spending per month on brackets?' and 'Which location is spending the most on PPE?'"
          icon={<BinocularsIcon />}
          className="basis-1/3"
        />

        <Feature
          heading="Price increase alerts"
          content="Our team is constantly monitoring your spend, so if a product you buy regularly raises its price, you'll be the first to know about it."
          icon={<PriceAlertIcon />}
          className="basis-1/3"
        />
      </div>

      <Quote author='Orthodontist'>
        I thought we had a good handle on our spend. Boy, was I wrong. We’re already saving money.
      </Quote>

      <CallToAction />
    </div>
  )
}