import Quote from '../../components/Quote'
import VimeoEmbed from '../../../../components/VimeoEmbed'
import CallToAction from './CallToAction'

export default function Story() {
  return (
    <div id="customers" className="flex flex-col container max-w-5xl mx-auto my-8 md:my-32 px-4">
      <div className="flex flex-col container max-w-xl mx-auto mb-8 md:mb-16">
        <h2 className="mb-4 text-2xl md:text-3xl font-bold">Don't just take our word for it</h2>
        <p className='leading-normal text-neutral-700'>In 2021, Depew Orthodontics grew production more than 41%, yet supply costs only increased 6% (compared to an anticipated 20%), thanks to their work with Details.</p>
      </div>

      <div className="flex flex-col md:flex-row gap-8 mb-16">
        <div className='basis-1/2 flex flex-col gap-6'>
          <h3 className='text-lg font-semibold'>Life before Details</h3>
          <VimeoEmbed id='590020651' title='Life before Details video' />
          <p className='leading-normal text-neutral-700'>Before using Details, purchasing supplies was chaotic for clinical staff at Depew Orthodontics. Orders were placed with suppliers across countless e-commerce sites, email chains with sales people, and phone calls.</p>
          <Quote author='Lauren Martin, Purchasing Staff at Depew Orthodontics'>The hardest part about purchasing, for me, is probably that there's so many items and so many different companies, and it doesn't matter how organized you are, it's just extremely time consuming.</Quote>
        </div>

        <div className='basis-1/2 flex flex-col gap-6'>
          <h3 className='text-lg font-semibold'>Life with Details</h3>
          <VimeoEmbed id='593554217' title='Life with Details video' />
          <p className='leading-normal text-neutral-700'>Noticing the stress this caused staff, the leadership at Depew Orthodontics started using Details Concierge. Now that purchasing is organized in one place, staff feel confident they have a handle on ordering, are saving time, and saving money on supplies.</p>
          <Quote author='Kenneth Moss, Practice Manager at Depew Orthodontics'>I would venture to say that we're probably saving 5&ndash;10 hours a week on the clinical side between our clinical coordinator and our inventory manager, so it's a big plus for us.</Quote>
        </div>
      </div >

      <CallToAction />
    </div >
  )
}