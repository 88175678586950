export default function Feature(props) {
  const { icon, heading, content, ...rest } = props

  return (
    <div {...rest}>
      <div className="mb-4 w-[3rem] h-[3rem] p-2 bg-red-200 rounded-md text-xs text-red-800 flex items-center justify-center">
        {icon}
      </div>

      <h3 className="mb-2 text-lg font-semibold">
        {heading}
      </h3>

      <p className="text-neutral-700">
        {content}
      </p>
    </div>
  )
}