import {
  Concierge,
  Contact,
  Hero,
  Platform,
  Reporting,
  Story
} from './components'

import {
  Navigation,
  Footer,
  Spacer
} from '../components'

export default function Home() {
  return (<>
    <Navigation />
    <Hero />
    <Spacer />
    <Concierge />
    <Spacer />
    <Reporting />
    <Spacer />
    <Platform />
    <Spacer />
    <Story />
    <Footer />
  </>)
}