export default function PlatformStep(props) {
  const { number, title, image, children } = props

  return (
    <li className='relative flex flex-col md:flex-row-reverse py-8 md:py-16'>
      <div className='absolute ml-4 md:mr-[calc(50%_-_1px)] md:ml-[calc(-50%_+_1px)] h-full -my-8 md:-my-16 border-l-2 border-red-100 border-dashed' />
      <div className="absolute h-8 w-8 md:h-12 md:w-12 md:mr-[calc(50%_-_1.5rem)] md:ml-[calc(1.5rem_-_50%)] bg-red-500 border-white text-white font-extrabold md:text-xl border-4 rounded-full flex justify-center items-center">{number}</div>

      <div className='ml-12 md:ml-16 mb-6 md:mb-0 md:basis-1/2'>
        <h3 className='mb-2 text-lg font-semibold'>{title}</h3>
        <div>{children}</div>
      </div>

      <figure className='ml-12 md:ml-0 md:mr-16 md:basis-1/2'>
        <img src={image} alt={title} />
      </figure>
    </li>
  )
}