import { ReactComponent as DetailsLogo } from '../../../assets/logo.svg'

export default function Footer() {
  return (
    <div className='flex flex-col md:flex-row gap-3 md:gap-6 px-3 md:px-6 py-6 align-center'>
      <DetailsLogo alt='Orthodontic Details Logo' className="mx-auto md:mx-none w-[1.25rem] h-[1.25rem] text-gray-400" />

      <p className="flex-1 text-sm text-center md:text-right text-gray-400">
        &copy; {new Date().getFullYear()} Orthodontic Details, LLC. All rights reserved.
      </p>
    </div>
  )
}
