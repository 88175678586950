import Customer from './Customer'

import laurenPortrait from '../../../../assets/images/marketing/lauren-portrait.jpg'
import laurenLandscape from '../../../../assets/images/marketing/lauren-landscape.jpg'
import stephaniePortrait from '../../../../assets/images/marketing/stephanie-portrait.jpg'
import vincePortrait from '../../../../assets/images/marketing/vince-portrait.jpg'

const desktopCustomers = [
  {
    id: 1,
    image: laurenPortrait,
    altText: 'A photo of Lauren, a customer of Orthodontic Details',
    caption: 'Meet Lauren, who spent 10 hours a month on purchasing before Details. Now she spends just 1 hour a month.',
  },
  {
    id: 2,
    image: stephaniePortrait,
    altText: 'A photo of Stephanie, a customer of Orthodontic Details',
    caption: 'Meet Stephanie, who saved her practice over $11k in 2021 using Details.',
  },
  {
    id: 3,
    image: vincePortrait,
    altText: 'A photo of Dr. Hansen, a customer of Orthodontic Details',
    caption: 'Meet Dr. Hansen, who recouped 120 hours of staff time to use on billable clinical work with Details.',
  }
]

const mobileCustomer = {
  image: laurenLandscape,
  altText: 'A photo of Lauren, a customer of Orthodontic Details',
  caption: 'Meet Lauren, who spent 10 hours a month on purchasing before Details. Now she spends just 1 hour a month.'
}

export default function Hero() {
  return (<>
    <div className="container max-w-5xl mx-auto my-12 md:mt-32 md:mb-16 px-2 md:px-4">
      <h1 className="text-4xl md:text-6xl text-center md:text-left font-bold md:leading-tight">
        Simplify purchasing for your orthodontic practice
      </h1>
    </div>

    <div className="hidden md:flex flex-col md:flex-row gap-12 container max-w-5xl mx-auto my-8 md:mb-32 md:px-4">
      {desktopCustomers.map(customer => <Customer key={customer.id} customer={customer} className='basis-1/3' />)}
    </div>

    <div className="flex md:hidden w-full p-4">
      <Customer customer={mobileCustomer} className='w-screen' />
    </div>
  </>)
}