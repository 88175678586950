import { useLayoutEffect, useRef } from 'react'

export default function VimeoEmbed({ id, title }) {
  const iframeRef = useRef()

  useLayoutEffect(() => {
    // Safari on iOS 14 doesn't support the `aspect-ratio` CSS property, and it
    // is still a fairly common browser.
    //
    // If we detect that the browser doesn't support `aspect-ratio`, we'll
    // manually set the `height` property to ensure the embed's aspect ratio is
    // set correctly.
    //
    // Once iOS 14 is no longer a common browser, we can remove this code and
    // rely entirely on `aspect-ratio` in CSS.
    if (!CSS.supports('aspect-ratio', '16 / 9')) {
      iframeRef.current.height = iframeRef.current.clientWidth * (9 / 16)
    }
  })

  return (
    <iframe
      ref={iframeRef}
      src={`https://player.vimeo.com/video/${id}`}
      title={title}
      className='rounded-md'
      width='100%'
      style={{ aspectRatio: '16 / 9' }}
      frameBorder='0'
      allow='autoplay; fullscreen'
      allowFullScreen />
  )
}