import NavigationItem from './NavigationItem'
import { ReactComponent as DetailsLogo } from '../../../assets/logo.svg'

export default function Navigation() {
  const scrollToId = (id) => {
    const yOffset = -100;
    const element = document.getElementById(id);

    let y = 0

    if (element) {
      y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    }

    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  return (
    <div className="sticky top-0 z-50 flex gap-6 p-3 md:px-6 align-center bg-white/80 backdrop-blur supports-backdrop-blur:bg-white/60 border-b">
      <div onClick={() => scrollToId('top')} className="cursor-pointer flex items-center text-neutral-900">
        <DetailsLogo alt='Orthodontic Details Logo' className="h-[1.25rem] w-[1.25rem] mr-2 text-red-500" />
        <span className="font-semibold md:text-base leading-none">Details</span>
      </div>

      <div className='hidden md:flex items-center gap-6'>
        <NavigationItem title="Concierge" appearance="link" onClick={() => scrollToId('concierge')} />
        <NavigationItem title="Reporting" appearance="link" onClick={() => scrollToId('reporting')} />
        <NavigationItem title="Platform" appearance="link" onClick={() => scrollToId('platform')} />
        <NavigationItem title="Customers" appearance="link" onClick={() => scrollToId('customers')} />
      </div>

      <div className="flex flex-1 justify-end items-center gap-4">
        <NavigationItem title="Login" appearance="link" href="https://app.orthodonticdetails.com" />
        <NavigationItem title="Schedule a Demo" appearance="button" href="https://calendly.com/orthodonticdetails" />
      </div>
    </div>
  )
}
