import Quote from '../../components/Quote'
import PlatformStep from './PlatformStep'
import CallToAction from './CallToAction'

import platformAdd from '../../../../assets/images/marketing/platform-add.png'
import platformTrack from '../../../../assets/images/marketing/platform-track.png'
import platformReorder from '../../../../assets/images/marketing/platform-reorder.png'
import platformShipping from '../../../../assets/images/marketing/platform-shipping.png'

export default function MarketingHomePlatform() {
  return (
    <div id="platform" className='flex flex-col container max-w-5xl mx-auto my-8 md:my-32 px-4'>
      <div className="flex flex-col container md:max-w-[50%] md:mx-auto md:text-center gap-2 pb-8">
        <h2 className="text-2xl md:text-3xl font-bold">Order everything in one place</h2>
        <p className='mb-4 font-semibold text-red-300'>(Yes, everything)</p>
        <p className="leading-normal text-neutral-700">Turn the complex, time-consuming, and demoralizing task of ordering supplies into a simple 3-step process with Details' ordering dashboard.</p>
      </div>

      <ul>
        <PlatformStep number="1" title="Submit all of your orders in one place" image={platformAdd}>
          <p className='mb-6 text-neutral-700'>Your staff shouldn't need to spend hours calling and emailing your dozens of suppliers just to get the supplies you need to run your practice. Their time is better spent on clinical work and important projects that have the highest leverage impact on your patients and business.</p>
          <p className='mb-6 text-neutral-700'>With Details, submitting orders to all of your existing suppliers is simple and takes minutes, giving your staff time back they can use to focus on the work that really matters.</p>
          <Quote author='Purchasing Staff Member'>We just started and I already feel the relief</Quote>
        </PlatformStep>

        <PlatformStep number="2" title="Easily track orders as they progress" image={platformTrack}>
          <p className='mb-6 text-neutral-700'>Eliminate time-consuming follow-ups with suppliers to get the latest status of your orders. </p>
          <p className='mb-6 text-neutral-700'>With Details, your team will always know exactly where each and every order is at any time, with built-in live package tracking, and instant alerts when a product is back-ordered.</p>
          <p className='text-neutral-500 text-sm mb-2'>We support real-time tracking and alerts for all the major shipping providers.</p>
          <img src={platformShipping} alt='Logos of USPS, FedEx, and UPS' width='216' height='47' />
        </PlatformStep>

        <PlatformStep number="3" title="Re-order in just one click" image={platformReorder}>
          <p className='text-neutral-700'>We know that most of your practice's orders are re-orders, so it only takes one click to reorder something you've ordered before, giving you back your staff's valuable time.</p>
        </PlatformStep>
      </ul>

      <CallToAction />
    </div>
  )
}
