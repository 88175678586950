export default function NavigationItem({ title, appearance, href, onClick }) {
  const linkClassName = 'font-medium text-sm text-neutral-800 hover:text-neutral-600'
  const buttonClassName = 'font-medium text-sm text-white bg-red-500 px-3 py-2 rounded-md hover:ring hover:ring-red-200 hover:bg-red-600'
  const className = appearance === 'button' ? buttonClassName : linkClassName

  if (!href && !onClick) {
    throw new Error('NavigationItem requires either `href` or `onClick`')
  } else if (onClick) {
    return <button onClick={onClick} className={className}>{title}</button>
  } else {
    return <a href={href} className={className}>{title}</a>
  }
}